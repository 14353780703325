/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Typography from "root/components/Typography";
import { useTranslation } from "react-i18next";
import legalStyles from "../../sections/LegalHero/index.module.css";
import LegalDocument from "../legal-document";

function TermsAndConditions({ launcher }) {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage("en");
  }, []);

  return (
    <LegalDocument
      t={t}
      title={t("pages.termsConditions.label")}
      bodyTitle={t("pages.termsConditions.title")}
      currentPage="termsConditions"
      launcher={launcher}
    >
      <Typography variant="h2" color="yellow" weight="medium">
        Sioslife – For Younger Spirits (Sioslife S.A.)
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          These General Conditions of Use of Sioslife® solutions should be read
          carefully before using any of the Sioslife® solutions. The use of
          Sioslife® solutions indicates that the User is aware of these General
          Conditions of Use and agrees to them. These General Conditions of Use
          are published at &quot;
          <a href={launcher ? "#" : "https://sioslife.com"}>
            https://sioslife.com
          </a>
          &quot;, and may be consulted at any time.
        </Typography>
      </div>
      <br />
      <br />
      <br />
      <Typography variant="h3" color="yellow" weight="medium">
        1. Parties
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          The parties to these General Conditions are: Sioslife S.A. and the
          User, who accesses the Sioslife® platforms, as a user of Sioslife®
          solutions, family member or friend/acquaintance of a user of Sioslife®
          solutions, or the User who, as a professional of an entity or
          institution that has Sioslife® solutions, accesses the employee area
          on the online platform, mobile applications or system installed in
          that same entity or institution.
        </Typography>
      </div>
      <br />
      <br />
      <br />
      <Typography variant="h3" color="yellow" weight="medium">
        2. Object
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          These conditions regulate the non-exclusive and non-transferable
          license to use Sioslife® solutions. The license to use Sioslife®
          solutions may be free or onerous, depending on the type of user, the
          solution and the commercial conditions agreed and contracted between
          the parties.
        </Typography>
      </div>
      <br />
      <br />
      <br />
      <Typography variant="h3" color="yellow" weight="medium">
        3. Definitions
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          Sioslife® solutions are interactive systems that result from the
          combination of hardware and software or just software, and that can be
          made available in a fixed-use environment (desktop) or mobile use
          (tablet or smartphone). The Sioslife® software includes several
          functionalities designed to combat the social withdrawal and the
          sedentary lifestyle of the ageing population. These features are
          customized according to the characteristics of each user. Around each
          user, their family, friends, and carers can communicate, monitor and
          follow up on the day-to-day life and activities of seniors, as well as
          system management and configuration features.
        </Typography>
      </div>
      <br />
      <br />
      <br />
      <Typography variant="h3" color="yellow" weight="medium">
        4. Description and Operating Requirements of Sioslife® Solutions
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          The Sioslife® Software, an integral part of the Sioslife® solutions,
          works on Windows, Android and iOS operating systems, as well as on the
          most common web browsers. The application allows text data entry and
          the upload of image and video files. In terms of data output, all of
          these are at a graphical and visual level, with the graphical
          appearance determined by the structure of the application. The
          software has no specific needs to work, only a device with an internet
          connection and text input is required. The application guarantees
          operation on Tablets, Desktops/Laptops and smartphones. Data
          transmission on the platform is done securely, with data being sent in
          encrypted form.
        </Typography>
      </div>
      <br />
      <br />
      <br />
      <Typography variant="h3" color="yellow" weight="medium">
        5. Acquisition of user licenses
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          The provision of the Sioslife® solutions subscription service may be
          subject to the prior payment by the User of a user license.
        </Typography>
      </div>
      <br />
      <br />
      <br />
      <Typography variant="h3" color="yellow" weight="medium">
        6. Company Obligations
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          All data is stored in an encrypted database, and it is the
          responsibility of Sioslife S.A. to store all the information entered
          by the User on the platform. Sioslife S.A. guarantees the
          confidentiality of all data entered by the User on the platform. The
          entire interface has been created to facilitate the execution of all
          functionalities. Sioslife S.A. guarantees that, within the limitations
          of the application, all functionalities will work as stipulated on the
          Sioslife® Software help page.
        </Typography>
      </div>
      <br />
      <br />
      <br />
      <Typography variant="h3" color="yellow" weight="medium">
        7. Company Responsibility
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          The suspension of the services provided through the Sioslife®
          platforms for reasons not attributable to Sioslife S.A. does not give
          the User the right to be indemnified or compensated, in any way, for
          any damage, patrimonial or otherwise, that may arise due to said
          suspension. Sioslife S.A. is not liable to third parties for any
          damages or losses caused by acts of the User that result in the misuse
          of the platform or in the violation of the terms and conditions of
          this User Licence Agreement. In the event of deletion by the User of
          any information or data previously entered by him/her on the platform,
          Sioslife S.A. is under no obligation to recover that information.
          Sioslife S.A. is not responsible for the quality operation of the
          application in other browsers and operating systems other than those
          specified in point 4. Sioslife S.A. is not responsible for processing
          information with quality when for reasons attributable to the speed of
          the User&apos;s internet connection.
        </Typography>
      </div>
      <br />
      <br />
      <br />
      <Typography variant="h3" color="yellow" weight="medium">
        8. User Obligations
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          Access to the platform is realized when the User enters his/her ID and
          a valid password, or through the Simplified Login System built for
          senior users, where there is no need to enter credentials such as
          username and password. The User is authorized and able to change the
          password at any time. The User can recover the password. Sioslife S.A.
          recommends that the User change the password regularly and that he/she
          does not disclose his/her access codes to any person who is not
          authorized to perform acts on his/her behalf. The User accepts full
          responsibility for the security of the User ID and password. This
          includes unauthorised access by third parties, including persons
          acting on behalf of, in the name of, representing or under
          instructions from the User, whether arising from employment or
          otherwise. All data and media archiving activity in the Sioslife®
          Software is the responsibility of the User. The User agrees not to use
          the platform to: Defame, abuse, harass, stalk, threaten or otherwise
          violate the rights of others; Publish, post, upload, distribute or
          disseminate any and all types of inappropriate, defamatory, obscene,
          disrespectful or illegal material and/or information; Upload files or
          any other type of material that is protected by the Copyright Code or
          that violates personal data protection/privacy legislation and any
          other property rights, except in cases where the User holds or has
          received the necessary authorisations to do so; Upload files that
          contain viruses, trojan horses, worms, time bombs, cancelboots,
          corrupt files or any other type of identical files that are likely to
          damage the computer, smartphone, tablet, similar device or property of
          others; Illegally download, or subject to any other prohibition, files
          belonging to third parties or other users. The User is not allowed to:
          Falsify, omit or delete any authorial, legal or any other type of
          proprietary labels/designations on the origin/source of the
          software/material contained in an uploaded file; Manipulate or falsify
          information in order to conceal the origin of products or services;
          Record, collect, retain or collect information about other users;
          Violate any and all applicable laws/regulations, in particular those
          governing copyright protection and rights, as well as corrupt or alter
          procedures, policies, requirements or regulations connected with the
          services; Create a false identity with the aim of deceiving others.
        </Typography>
      </div>
      <br />
      <br />
      <br />
      <Typography variant="h3" color="yellow" weight="medium">
        9. Licensing and Copyright
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          The Sioslife® Software is not sold but licensed to the User. This
          license is effective from the date of acceptance of the User License
          Agreement and its full payment. The User may not unless expressly
          authorized by Sioslife S.A. to do so: Copy any part of the software;
          Distribute, rent, sublicense, transfer or disclose, by any method, or
          transmit the Sioslife® Software to any person; Modify, translate,
          merge or prepare derivative works of the Sioslife® Software; Use any
          part of the software for purposes not specified and/or authorized by
          Sioslife, SA.
        </Typography>
      </div>
      <br />
      <br />
      <br />
      <Typography variant="h3" color="yellow" weight="medium">
        10. Changes to the General Conditions
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          Sioslife S.A. may modify these General Conditions of Use in the
          future. Any contractual amendment or modification will be communicated
          to the User by means of an online notice. The User agrees that the use
          of Sioslife® solutions, after having received notice of a change,
          amendment, or addition to this contract, will constitute acceptance of
          said changes, amendments, or additions.
        </Typography>
      </div>
      <br />
      <br />
      <br />
      <Typography variant="h3" color="yellow" weight="medium">
        11. Privacy and the Right of Access to Data
      </Typography>
      <div className={legalStyles.description}>
        <Typography color="black">
          By this contract, the User agrees that Sioslife S.A. may collect and
          use technical information that is provided by him/her in relation to
          support services related to the Sioslife® Software. The personal data
          collected by Sioslife S.A. are contained in a protected database,
          being confidential and used exclusively to fulfil the legal
          obligations of Sioslife, SA. and automatically processed in accordance
          with Law No. 58/2019 of 08 August and Regulation (EU) 2016/679 of the
          Parliament and of the Council of 27 April 2016. Under the terms of the
          applicable legislation, Sioslife S.A. undertakes to grant the User
          whose file is collected, the right to access, oppose, rectify and
          delete their data, as stipulated in the Privacy and Data Protection
          Policy, available at{" "}
          <a href={launcher ? "#" : "https://www.sioslife.com"}>
            https://www.sioslife.com
          </a>
          .
        </Typography>
      </div>
    </LegalDocument>
  );
}

TermsAndConditions.propTypes = {
  launcher: PropTypes.bool,
};

TermsAndConditions.defaultProps = {
  launcher: false,
};

export default TermsAndConditions;
